@font-face {
  font-family: "Geist";
  src: url("../assets/fonts/geist/Geist-Regular.woff2") format("woff2"),
    url("../assets/fonts/geist/Geist-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Geist";
  src: url("../assets/fonts/geist/Geist-Bold.woff2") format("woff2"),
    url("../assets/fonts/geist/Geist-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
