.customDrawerPaper {
  border: 1px solid #f3f4f6 !important;
  border-radius: 8px !important;
  margin: 12px !important;
  margin-top: 84px !important;
  background-color: #f3f4f6 !important;
  height: auto !important;
}

/* Styles for List within customDrawerPaper */
.customDrawerPaper .MuiList-root {
  padding: 16px !important;
}

/* Styles for ListItem within customDrawerPaper */
.customDrawerPaper .MuiListItem-root {
  padding: 8px 16px;
  border-radius: 8px;
  margin-bottom: 4px;
}

/* Hover effect for ListItem within customDrawerPaper */
.customDrawerPaper .MuiListItem-root:hover {
  background-color: #f5f5f5;
}

/* Icon color within ListItem in customDrawerPaper */
.customDrawerPaper .MuiListItemIcon-root {
  color: #64748b;
}

/* Primary text color within ListItem in customDrawerPaper */
.customDrawerPaper .MuiListItemText-primary {
  color: #353535;
  font-weight: 500;
}

/* Secondary text color within ListItem in customDrawerPaper */
.customDrawerPaper .MuiListItemText-secondary {
  color: #64748b;
  font-weight: 400;
}

/* Active ListItem styling within customDrawerPaper */
.customDrawerPaper .MuiListItem-root.active {
  background-color: #353535;
  border-radius: 8px;
}

/* Icon color for active ListItem within customDrawerPaper */
.customDrawerPaper .MuiListItem-root.active .MuiListItemIcon-root {
  color: #fff;
}

/* Hover effect for active ListItem icon within customDrawerPaper */
.customDrawerPaper .MuiListItem-root.active:hover .MuiListItemIcon-root {
  color: #353535 !important;
}

/* Primary text color for active ListItem within customDrawerPaper */
.customDrawerPaper .MuiListItem-root.active .MuiListItemText-primary {
  color: #fff !important;
  font-weight: 500;
}

/* Hover effect for active ListItem text within customDrawerPaper */
.customDrawerPaper .MuiListItem-root.active:hover .MuiListItemText-primary {
  color: #353535 !important;
  font-weight: 500;
}