.grid-left {
    border-right: 1px solid lightgray;
    padding-right: 24px;
}

.table-row-hover:hover {
    background-color: #f0f0f0;
}

.cancel-button {
    margin-right: 8px;
}

.confirm-button {
    min-width: 100px;
}